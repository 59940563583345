.has-text-white {
    a {
        color: white;
    }
}

.sidebar h2 {
    @include mobile {
        font-size: 1.5rem;
    }
}
