@import "variables";
// @import "bulma/bulma";
@import "bulma";

@import "components/logo";
@import "components/header";
@import "components/stage";
@import "components/modifiers";
@import "components/bio";
@import "components/carousel";
@import "components/sidebar";
@import "components/footer";
@import "components/text";

html {
  box-sizing: border-box;
}

.content {
  overflow-wrap: break-word;
}

a {
  text-decoration: underline;
}

.is-flex-centered {
  justify-content: center;
}

img, svg {
  vertical-align: middle;
}

.red-dot:after {
  content: '.';
  color: $red;
}

.hero + p {
  margin-top: 1em;
}

.is-flex {
  &.is-flex-column {
    flex-direction: column;
  }
}
