.card-image .stage .cloud {
  font-size: 0.02em;
}

.stage {
  background-size: cover;

  .sparks {
    text-align: center;

    span {
      padding: 4em;
      background: url("/svg/text-decoration.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;

      @include mobile {
        padding: 2.5em;
      }
    }
  }

  .cloud {
    background-color: $primary;
    text-align: center;

    span {
      display: block;
      color: white;
    }

    span {
      $duration: 5s;
      opacity: 0;
      position: relative;
      animation: $duration ease-in-out infinite fade-in-out;

      @keyframes fade-in-out {
        0% {
          opacity: 0;
          transform: scale(0.9);
        }

        50% {
          opacity: 1;
          transform: scale(1);
        }

        75% {
          opacity: 1;
          transform: scale(1);
        }

        100% {
          opacity: 0;
          transform: scale(0.99);
        }
      }

      &:nth-of-type(1) {
        // font-size: 1.2em;
        font-size: calc(#{$body-font-size} + 2.2vw);
        color: rgba(white, 0.8);
        left: -2em;
        left: 2.9vw;
        animation-delay: $duration * 0.2;
      }
      &:nth-of-type(2) {
        // font-size: 2em;
        font-size: calc(#{$body-font-size} + 1.6vw);
        color: rgba(white, 0.7);
        left: 6em;
        left: 18vw;
        animation-delay: $duration * 0.0;
      }
      &:nth-of-type(3) {
        // font-size: 3.2em;
        font-size: calc(#{$body-font-size} + 3.9vw);
        color: rgba(white, 0.9);
        // left: -3em;
        left: -2vw;
        animation-delay: $duration * 0.6;
      }
      &:nth-of-type(4) {
        font-size: 1.8em;
        font-size: calc(#{$body-font-size} + 1.0vw);
        color: rgba(white, 0.5);
        left: 3em;
        left: 8vw;
        animation-delay: $duration * 0.4;
      }
      &:nth-of-type(5) {
        font-size: 1.8em;
        font-size: calc(#{$body-font-size} + 1.3vw);
        color: rgba(white, 0.6);
        left: 2em;
        left: 5vw;
        animation-delay: $duration * 0.8;
      }
    }
  }
}
