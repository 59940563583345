footer.footer {
    .email {
        content: "\0040";
        unicode-bidi: bidi-override;
        direction: rtl;

        &:before {
            content: attr(data-niamod);
        }
        &:after {
            content: attr(data-eman) ;
        }
    }
}
