.navbar {
  @include desktop {
    padding: 50px 0;
  }

  padding: 20px 0;

  .navbar-menu {
    &.is-active {
      position: fixed;
      right: 0;
    }
  }
  
  a.navbar-item {
    text-decoration: none;
  }

  .navbar-end {
    @include widescreen {
      font-size: 1.2em;
    }
  }
}
