.carousel-container {
    overflow: hidden;

    .carousel {
        display: flex;
        flex-wrap: nowrap;

        transition: transform 1s ease-in-out;

        > .slide {
            display: flex;
            width: 100%;
            height: 350px;
            background-image: linear-gradient(grey, lightgrey);

            @include touch {
                max-height: calc(60vw - 150px);
            }

            h2 {
                margin: auto auto;

                font-size: 3rem;

                @include touch {
                    font-size: calc(5vw - -0.7rem);
                }
            }

            img {
                height: 100%;
                width: auto;
            }
        }
    }
}
