.navbar-logo {

  @include desktop-only {
    font-size: 0.9rem;
  }

  > span {
    display: block;

    &:first-child {
      font-size: 2em;
      font-weight: bold;
      line-height: 1em;
    }
    &:last-child {
      font-style: italic;
      letter-spacing: 0.05em;
    }
  }
}

.logo {
  filter: grayscale(100%);
  transition: filter 0.2s ease-in-out;
  width: 250px;

  &:hover {
    filter: none;
  }
}
